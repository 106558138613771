<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <!-- row -->
  <div class="container-fluid">
    <div class="row">
      <!-- Carosal -->
    
    
      <!-- DONAT DASH -->
      <!-- </div> -->
      <div class="col-xl-12" *ngIf="isPeserta">
        <div class="card">
          <!-- START -->
          <ngb-carousel class="default-carousel" *ngIf="images">
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <a href="https://whatsapp.com/channel/0029Vad1irH77qVZzMto1r2s" target="_blank">
                  <img [src]="images[0]" alt="Siger.co.id" style="height: 100%; width: 100%;">
                </a>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <a href="https://siger.co.id/bhayangkara-run-2024-by-run-a-way/" target="_blank">
                  <img [src]="images[1]" alt="Siger.co.id" style="height: 100%; width: 100%;">
                </a>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
        
      </div>
      <!-- Carosal -->      
      <!-- END COUNT DOWN -->
      <!-- START SUPER -->
      <!-- <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">3K - (Fun Walk)</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum3KMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum3KFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">5K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">10K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">21K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">21K - (UP50)</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KMMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KMFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isSuperadmin ">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">21K - (UP40)</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KM1Males}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KM1Females}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">5K - BYK</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">10K - BYK</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KRMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KRFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <!-- END SUPER -->

      <!-- 1 -->
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">3K - (Fun Walk)</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum3KMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum3KFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">5K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">10K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">21K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">21K - (UP50)</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KMMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KMFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 1 ">
        <div class="card border coin-holding-card border-info">
            <div class="card-body bg-info rounded">
                <span class="text-white">21K - (UP40)</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KM1Males}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KM1Females}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- <div class="col-xl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-white">PERSENTASE (KRAKATAU PSMTI RUN)</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>{{totalshawbkgpercen}} % </p>
            <p><ngb-progressbar type="warning" [value]="totalshawbkgpercen" [striped]="true" [animated]="true"><i> {{totalshawbkgpercen}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 2">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-white">PERSENTASE (RUNAWAY)</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>{{totalshawbkgpercen2}} % </p>
            <p><ngb-progressbar type="warning" [value]="totalshawbkgpercen2" [striped]="true" [animated]="true"><i> {{totalshawbkgpercen2}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 3">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-white">PERSENTASE (IMANUEL RUN)</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>{{totalshawbkgpercen3}} % </p>
            <p><ngb-progressbar type="warning" [value]="totalshawbkgpercen3" [striped]="true" [animated]="true"><i> {{totalshawbkgpercen3}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-white">PERSENTASE (ONAGO HALAL RUN)</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>{{totalshawbkgpercen4}} % </p>
            <p><ngb-progressbar type="warning" [value]="totalshawbkgpercen4" [striped]="true" [animated]="true"><i> {{totalshawbkgpercen4}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 5">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-white">PERSENTASE (PENTOSA RUN)</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>{{totalshawbkgpercen5}} % </p>
            <p><ngb-progressbar type="warning" [value]="totalshawbkgpercen5" [striped]="true" [animated]="true"><i> {{totalshawbkgpercen5}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div> -->
      <!-- WAHAHA -->
      <!-- <div class="col-xl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 6">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-white">PERSENTASE (WAHAHA RUN)</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>{{totalshawbkgpercen6}} % </p>
            <p><ngb-progressbar type="warning" [value]="totalshawbkgpercen6" [striped]="true" [animated]="true"><i> {{totalshawbkgpercen6}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div> -->

      <!-- <div class="col-xl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 7">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-white">PERSENTASE (BRIMOB RUN)</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>{{totalshawbkgpercen7}} % </p>
            <p><ngb-progressbar type="warning" [value]="totalshawbkgpercen7" [striped]="true" [animated]="true"><i> {{totalshawbkgpercen7}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 7">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">5K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KBRMMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KBRMFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 7">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">10K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KBRMMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KBRMFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->

      <div class="col-xl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 8">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-white">PERSENTASE (LOCOMOTIVE)</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>{{totalshawbkgpercen8}} % </p>
            <p><ngb-progressbar type="warning" [value]="totalshawbkgpercen8" [striped]="true" [animated]="true"><i> {{totalshawbkgpercen8}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 8">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">5K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KLMRMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KLMRFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 8">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">10K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KLMRMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KLMRFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>


      <!-- <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">TOTAL: {{sum3KMales+sum3KFemales+sum5KMales+sum5KFemales+sum10KMales+sum10KFemales+sum21KMales+sum21KFemales+sum21KMMales+sum21KMFemales+sum21KM1Males+sum21KM1Females}}</span>
            </div>
            <div class="row">
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum3KMales+sum3KFemales}}</span> </h4>
                        <p class="m-0">3K</p>
                    </div>
                </div>
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KMales+sum5KFemales}}</span></h4>
                        <p class="m-0">5K</p>
                    </div>
                </div>
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KMales+sum10KFemales}}</span></h4>
                        <p class="m-0">10K</p>
                    </div>
                </div>
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KMales+sum21KFemales}}</span></h4>
                        <p class="m-0">21K</p>
                    </div>
                </div>
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KMMales+sum21KMFemales}}</span></h4>
                        <p class="m-0">21K UP50</p>
                    </div>
                </div>
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum21KM1Males+sum21KM1Females}}</span></h4>
                        <p class="m-0">21K UP40</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-success">
            <div class="card-body bg-success rounded">
                <span class="text-white">Monitoring Kategori</span>
            </div>
            <div class="row">
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS3K}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS3K}}</span> </h6>
                        <p class="m-0">3K - ({{VsumSaldoS3K}})</p>
                    </div>
                </div>
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS5K}}</span> </h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS5K}}</span> </h6>
                      <p class="m-0">5K - ({{VsumSaldoS5K}})</p>
                    </div>
                </div>
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS10K}}</span> </h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS10K}}</span> </h6>
                      <p class="m-0">10K - ({{VsumSaldoS10K}})</p>
                    </div>
                </div>
                <div class="col-2 border-right">
                  <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                    <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS21K}}</span> </h6>
                    <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS21K}}</span> </h6>
                    <p class="m-0">21K - ({{VsumSaldoS21K}})</p>
                  </div>
                </div>
                <div class="col-2 border-right">
                  <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                    <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS21KM}}</span> </h6>
                    <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS21KM}}</span> </h6>
                    <p class="m-0">21K 50 - ({{VsumSaldoS21KM}})</p>
                  </div>
                </div>
                <div class="col-2 border-right">
                  <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                    <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS21KM1}}</span> </h6>
                    <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS21KM1}}</span> </h6>
                    <p class="m-0">5K 40- ({{VsumSaldoS21KM1}})</p>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <!-- <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-danger">
            <div class="card-body bg-danger rounded">
                <span class="text-white">Total Pembayaran : {{this.currencyStr(Vsum3KLunas+Vsum5KLunas+Vsum10KLunas+Vsum21KLunas+Vsum21KM1Lunas)}} - {{VsumTotalPeserta3KLunas+VsumTotalPeserta5KLunas+VsumTotalPeserta10KLunas+VsumTotalPeserta21KLunas+VsumTotalPeserta21KMLunas+VsumTotalPeserta21KM1Lunas}} 
                  Peserta (KRAKATAU PSMTI RUN)
                </span>
            </div>
            <div class="row">
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum3KLunas)}}</span> </h6>
                        <p class="m-0">3K - {{VsumTotalPeserta3KLunas}} Peserta</p>
                    </div>
                </div>
                <div class="col-2 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum5KLunas)}}</span> </h6>
                        <p class="m-0">5K - {{VsumTotalPeserta5KLunas}} Peserta</p>
                    </div>
                </div>
                <div class="col-2">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum10KLunas)}}</span></h6>
                        <p class="m-0">10K - {{VsumTotalPeserta10KLunas}} Peserta</p>
                    </div>
                </div>
                <div class="col-2">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum21KLunas)}}</span></h6>
                        <p class="m-0">21K - {{VsumTotalPeserta21KLunas}} Peserta</p>
                    </div>
                </div>
                <div class="col-2">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum21KMLunas)}}</span></h6>
                        <p class="m-0">21K UP50 - {{VsumTotalPeserta21KMLunas}} Peserta</p>
                    </div>
                </div>
                <div class="col-2">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum21KM1Lunas)}}</span></h6>
                        <p class="m-0">21K UP40 - {{VsumTotalPeserta21KM1Lunas}} Peserta</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <!-- 1 TUTUP PSTM -->
     
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 2">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">5K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 3">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">5K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRIMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRIFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 4">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">5K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRIJMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRIJFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 5">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">5K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRIJKMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRIJKFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- WHR -->
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 6">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">5K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRIJLMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRIJLFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-3 col-xxl-3 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 2">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">10K</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KRMales}}</span> </h4>
                        <p class="m-0">Male</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KRFemales}}</span></h4>
                        <p class="m-0">Female</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 2">
        <div class="card border coin-holding-card border-warning">
            <div class="card-body bg-warning rounded">
                <span class="text-white">TOTAL: {{sum5KRMales+sum5KRFemales+sum10KRMales+sum10KRFemales}}</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum5KRMales+sum5KRFemales}}</span> </h4>
                        <p class="m-0">5K</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h4 class="m-1"><span class="counter">{{sum10KRMales+sum10KRFemales}}</span></h4>
                        <p class="m-0">10K</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- 2 -->
       <!-- TAGIHAN COUNT DOWN -->
       <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 2">
        <div class="card border coin-holding-card border-success">
            <div class="card-body bg-success rounded">
                <span class="text-white">Monitoring Kategori</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS5KR}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS5KR}}</span> </h6>
                        <p class="m-0">5K - ({{VsumSaldoS5KR}})</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaM10KR}}</span> </h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopM10KR}}</span> </h6>
                      <p class="m-0">10K - ({{VsumSaldoM10KR}})</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- BRM -->
      <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 7">
        <div class="card border coin-holding-card border-success">
            <div class="card-body bg-success rounded">
                <span class="text-white">Monitoring Kategori</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS5KBRM}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS5KBRM}}</span> </h6>
                        <p class="m-0">5K - ({{VsumSaldoS5KBRM}})</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaM10KBRM}}</span> </h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopM10KBRM}}</span> </h6>
                      <p class="m-0">10K - ({{VsumSaldoM10KBRM}})</p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <!-- LMR -->
      <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 8">
        <div class="card border coin-holding-card border-success">
            <div class="card-body bg-success rounded">
                <span class="text-white">Monitoring Kategori</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS5KLMR}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS5KLMR}}</span> </h6>
                        <p class="m-0">5K - ({{VsumSaldoS5KLMR}})</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaM10KLMR}}</span> </h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopM10KLMR}}</span> </h6>
                      <p class="m-0">10K - ({{VsumSaldoM10KLMR}})</p>
                    </div>
                </div>
            </div>
        </div>
      </div>


      <!-- <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 2">
        <div class="card border coin-holding-card border-danger">
            <div class="card-body bg-danger rounded">
                <span class="text-white">Total Pembayaran : Rp {{this.currencyStr(Vsum5KRLunas+Vsum10KRLunas)}} - {{VsumTotalPeserta5KRLunas+VsumTotalPeserta10KRLunas}} Peserta (RUNAWAY)</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum5KRLunas)}}</span> </h6>
                        <p class="m-0">5K - {{VsumTotalPeserta5KRLunas}} Peserta</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum10KRLunas)}}</span></h6>
                        <p class="m-0">10K - {{VsumTotalPeserta10KRLunas}} Peserta</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->

      <!-- IMA -->
      <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 3">
        <div class="card border coin-holding-card border-success">
            <div class="card-body bg-success rounded">
                <span class="text-white">Monitoring Kategori</span>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS5KRI}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS5KRI}}</span> </h6>
                        <p class="m-0">5K - ({{VsumSaldoS5KRI}})</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 3">
        <div class="card border coin-holding-card border-danger">
            <div class="card-body bg-danger rounded">
                <span class="text-white">Total Pembayaran : Rp {{this.currencyStr(Vsum5KRILunas)}} - {{VsumTotalPeserta5KRILunas}} Peserta (RUN IMANUEL)</span>
            </div>
            <div class="row">
                <div class="col-12 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum5KRILunas)}}</span> </h6>
                        <p class="m-0">5K - {{VsumTotalPeserta5KRILunas}} Peserta</p>
                    </div>
                </div>
                
            </div>
        </div>
      </div> -->

      <!-- <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum10KRLunas)}}</span></h6>
                        <p class="m-0">10K - {{VsumTotalPeserta10KRLunas}} Peserta</p>
                    </div>
                </div> -->

      <!-- ONA -->
      <!-- <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isAdmin && usersData.shipper_id == 4">
        <div class="card border coin-holding-card border-success">
            <div class="card-body bg-success rounded">
                <span class="text-white">Monitoring Kategori</span>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS5KRIJ}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS5KRIJ}}</span> </h6>
                        <p class="m-0">5K - ({{VsumSaldoS5KRIJ}})</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 4">
        <div class="card border coin-holding-card border-danger">
            <div class="card-body bg-danger rounded">
                <span class="text-white">Total Pembayaran : Rp {{this.currencyStr(Vsum5KRIJLunas)}} - {{VsumTotalPeserta5KRIJLunas}} Peserta (ONAGO HALAL RUN)</span>
            </div>
            <div class="row">
                <div class="col-12 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum5KRIJLunas)}}</span> </h6>
                        <p class="m-0">5K - {{VsumTotalPeserta5KRIJLunas}} Peserta</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <!-- END ONA -->
      <!-- <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 5">
        <div class="card border coin-holding-card border-danger">
            <div class="card-body bg-danger rounded">
                <span class="text-white">Total Pembayaran : Rp {{this.currencyStr(Vsum5KRIJKLunas)}} - {{VsumTotalPeserta5KRIJKLunas}} Peserta (PENTOSA RUN)</span>
            </div>
            <div class="row">
                <div class="col-12 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum5KRIJKLunas)}}</span> </h6>
                        <p class="m-0">5K - {{VsumTotalPeserta5KRIJKLunas}} Peserta</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <!-- END UNL -->
      <!-- <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 6">
        <div class="card border coin-holding-card border-danger">
            <div class="card-body bg-danger rounded">
                <span class="text-white">Total Pembayaran : Rp {{this.currencyStr(Vsum5KRIJLLunas)}} - {{VsumTotalPeserta5KRIJLLunas}} Peserta (WAHAHA RUN)</span>
            </div>
            <div class="row">
                <div class="col-12 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum5KRIJLLunas)}}</span> </h6>
                        <p class="m-0">5K - {{VsumTotalPeserta5KRIJLLunas}} Peserta</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <!-- END WHR -->

      <!-- START BRIM -->
      <!-- <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 7">
        <div class="card border coin-holding-card border-danger">
            <div class="card-body bg-danger rounded">
                <span class="text-white">Total Pembayaran : Rp {{this.currencyStr(Vsum5KBRMLunas+Vsum10KBRMLunas)}} - {{VsumTotalPeserta5KBRMLunas+VsumTotalPeserta10KBRMLunas}} Peserta (BRIMOB)</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum5KBRMLunas)}}</span> </h6>
                        <p class="m-0">5K - {{VsumTotalPeserta5KBRMLunas}} Peserta</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum10KBRMLunas)}}</span></h6>
                        <p class="m-0">10K - {{VsumTotalPeserta10KBRMLunas}} Peserta</p>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <!-- END BRIM -->

      <!-- START LMR -->
      <div class="col-xl-6 col-xxl-6 col-sm-6" *ngIf="isSuperadmin || isAdmin && usersData.shipper_id == 8">
        <div class="card border coin-holding-card border-danger">
            <div class="card-body bg-danger rounded">
                <span class="text-white">Total Pembayaran : Rp {{this.currencyStr(Vsum5KLMRLunas+Vsum10KLMRLunas)}} - {{VsumTotalPeserta5KLMRLunas+VsumTotalPeserta10KLMRLunas}} Peserta (Locomotive)</span>
            </div>
            <div class="row">
                <div class="col-6 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum5KLMRLunas)}}</span> </h6>
                        <p class="m-0">5K - {{VsumTotalPeserta5KLMRLunas}} Peserta</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">{{this.currencyStr(Vsum10KLMRLunas)}}</span></h6>
                        <p class="m-0">10K - {{VsumTotalPeserta10KLMRLunas}} Peserta</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- END LMR -->
      
       <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isAdmin && usersData.shipper_id == 1">
        <div class="card border coin-holding-card border-primary">
            <div class="card-body bg-primary rounded">
                <span class="text-white">Monitoring Size Jersey
                </span>
            </div>
            <div class="row">
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXS1}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXS1}}</span> </h6>
                        <p class="m-0">XS - ({{VsumSaldoXS1}}) </p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS1}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS1}}</span> </h6>
                        <p class="m-0">S - ({{VsumSaldoS1}}) </p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaM1}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopM1}}</span></h6>
                        <p class="m-0">M - ({{VsumSaldoM1}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaL1}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopL1}}</span></h6>
                        <p class="m-0">L - ({{VsumSaldoL1}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXL1}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXL1}}</span></h6>
                        <p class="m-0">XL - ({{VsumSaldoXL1}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXXL1}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXXL1}}</span></h6>
                        <p class="m-0">XXL - ({{VsumSaldoXXL1}})</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
       <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isAdmin && usersData.shipper_id == 2">
        <div class="card border coin-holding-card border-primary">
            <div class="card-body bg-primary rounded">
                <span class="text-white">Monitoring Size Jersey
                </span>
            </div>
            <div class="row">
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS}}</span> </h6>
                        <p class="m-0">S - ({{VsumSaldoS}}) </p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaM}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopM}}</span></h6>
                        <p class="m-0">M - ({{VsumSaldoM}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaL}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopL}}</span></h6>
                        <p class="m-0">L - ({{VsumSaldoL}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXL}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXL}}</span></h6>
                        <p class="m-0">XL - ({{VsumSaldoXL}})</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isAdmin && usersData.shipper_id == 3">
        <div class="card border coin-holding-card border-primary">
            <div class="card-body bg-primary rounded">
                <span class="text-white">Monitoring Size Jersey
                </span>
            </div>
            <div class="row">
                <div class="col-4 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS2}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS2}}</span> </h6>
                        <p class="m-0">S - ({{VsumSaldoS2}}) </p>
                    </div>
                </div>
                <div class="col-4 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaM2}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopM2}}</span></h6>
                        <p class="m-0">M - ({{VsumSaldoM2}})</p>
                    </div>
                </div>
                <div class="col-4 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaL2}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopL2}}</span></h6>
                        <p class="m-0">L - ({{VsumSaldoL2}})</p>
                    </div>
                </div>
                <div class="col-4 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXL2}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXL2}}</span></h6>
                        <p class="m-0">XL - ({{VsumSaldoXL2}})</p>
                    </div>
                </div>
                <div class="col-4 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXXL2}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXXL2}}</span></h6>
                        <p class="m-0">XXL - ({{VsumSaldoXXL2}})</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isAdmin && usersData.shipper_id == 4">
        <div class="card border coin-holding-card border-primary">
            <div class="card-body bg-primary rounded">
                <span class="text-white">Monitoring Size Jersey
                </span>
            </div>
            <div class="row">
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS4}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS4}}</span> </h6>
                        <p class="m-0">S - ({{VsumSaldoS4}}) </p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaM4}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopM4}}</span></h6>
                        <p class="m-0">M - ({{VsumSaldoM4}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaL4}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopL4}}</span></h6>
                        <p class="m-0">L - ({{VsumSaldoL4}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXL4}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXL4}}</span></h6>
                        <p class="m-0">XL - ({{VsumSaldoXL4}})</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- TAGIHAN UNLIA -->
      <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isAdmin && usersData.shipper_id == 5">
        <div class="card border coin-holding-card border-primary">
            <div class="card-body bg-primary rounded">
                <span class="text-white">Monitoring Size Jersey
                </span>
            </div>
            <div class="row">
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS5}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS5}}</span> </h6>
                        <p class="m-0">S - ({{VsumSaldoS5}}) </p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaM5}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopM5}}</span></h6>
                        <p class="m-0">M - ({{VsumSaldoM5}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaL5}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopL5}}</span></h6>
                        <p class="m-0">L - ({{VsumSaldoL5}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXL5}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXL5}}</span></h6>
                        <p class="m-0">XL - ({{VsumSaldoXL5}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                  <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXXL5}}</span></h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXXL5}}</span></h6>
                      <p class="m-0">XXL - ({{VsumSaldoXXL5}})</p>
                  </div>
              </div>
            </div>
        </div>
      </div>
      <!-- MONITORING WAHAHA -->
      <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isAdmin && usersData.shipper_id == 6">
        <div class="card border coin-holding-card border-primary">
            <div class="card-body bg-primary rounded">
                <span class="text-white">Monitoring Size Jersey
                </span>
            </div>
            <div class="row">
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaS6}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopS6}}</span> </h6>
                        <p class="m-0">S - ({{VsumSaldoS6}}) </p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaM6}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopM6}}</span></h6>
                        <p class="m-0">M - ({{VsumSaldoM6}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaL6}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopL6}}</span></h6>
                        <p class="m-0">L - ({{VsumSaldoL6}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXL6}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXL6}}</span></h6>
                        <p class="m-0">XL - ({{VsumSaldoXL6}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                  <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXXL6}}</span></h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXXL6}}</span></h6>
                      <p class="m-0">XXL - ({{VsumSaldoXXL6}})</p>
                  </div>
              </div>
            </div>
        </div>
      </div>

      <!-- MONITORING WAHAHA -->
      <div class="col-xl-12 col-xxl-12 col-sm-12" *ngIf="isAdmin && usersData.shipper_id == 8">
        <div class="card border coin-holding-card border-primary">
            <div class="card-body bg-primary rounded">
                <span class="text-white">Monitoring Size Jersey
                </span>
            </div>
            <div class="row">
              <div class="col-3 border-right">
                <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                    <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXSLMR6}}</span> </h6>
                    <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXSLMR6}}</span> </h6>
                    <p class="m-0">XS - ({{VsumSaldoXSLMR6}}) </p>
                </div>
            </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaSLMR6}}</span> </h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopSLMR6}}</span> </h6>
                        <p class="m-0">S - ({{VsumSaldoSLMR6}}) </p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaMLMR6}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopMLMR6}}</span></h6>
                        <p class="m-0">M - ({{VsumSaldoMLMR6}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaLLMR6}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopLLMR6}}</span></h6>
                        <p class="m-0">L - ({{VsumSaldoLLMR6}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                    <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                        <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXLLMR6}}</span></h6>
                        <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXLLMR6}}</span></h6>
                        <p class="m-0">XL - ({{VsumSaldoXLLMR6}})</p>
                    </div>
                </div>
                <div class="col-3 border-right">
                  <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXXLLMR6}}</span></h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXXLLMR6}}</span></h6>
                      <p class="m-0">XXL - ({{VsumSaldoXXLLMR6}})</p>
                  </div>
                </div>
                <div class="col-3 border-right">
                  <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXXXLLMR6}}</span></h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXXXLLMR6}}</span></h6>
                      <p class="m-0">XXXL - ({{VsumSaldoXXXLLMR6}})</p>
                  </div>
                </div>
                <div class="col-3 border-right">
                  <div class="pt-3 pb-3 pl-0 pr-0 text-center">
                      <h6 class="m-1"><span class="counter">Sisa: {{VsumSaldosisaXXXXLLMR6}}</span></h6>
                      <h6 class="m-1"><span class="counter">Terpakai: {{VsumSaldopXXXXLLMR6}}</span></h6>
                      <p class="m-0">XXXXL - ({{VsumSaldoXXXXLLMR6}})</p>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <!-- TAGIHAN COUNT DOWN -->


      <div class="col-xl-12 col-sm-6s" *ngIf="isSuperadmin">
        <div class="card">
            <div class="card-header border-0 pb-0">
                <h4 class="mb-0 text-black fs-20">Detail Pendaftaran</h4>
            </div>
            <div class="card-body p-3">
                <div class="table-responsive">
                    <table class="table text-center bg-info-hover tr-rounded">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">No.Invoice</th>
                          <th scope="col">Kategori</th>
                          <th scope="col">NIK/PASSPORT</th>
                          <th scope="col">Nama</th>
                          <th scope="col">No.BIB</th>
                          <!-- <th scope="col">Nama BIB</th> -->
                          <th scope="col">Size</th>
                          <th scope="col">Status Bayar</th>
                          <!-- <th scope="col">No Hp/Telp</th> -->
                          <th scope="col" *ngIf="isSuperadmin">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let tutorial of tutorials; index as x">
                          <th scope="row">
                            {{ x + 1 }}
                          </th>
                          <td>
                            {{ tutorial.reference == '' ? '' : tutorial.run_order_id }}
                          </td>
                          <td>
                            {{ tutorial.run_categoryText }}
                          </td>
                          <td>
                            {{ tutorial.run_idnumber }}
                          </td>
                          <td>
                            {{ tutorial.run_firstname }} {{ tutorial.run_lastname }}
                          </td>
                          <td>
                            {{ tutorial.run_nobip }}
                          </td>
                          <td>
                            {{ tutorial.run_sizekaos }}
                          </td>
                          <!-- <td>
                            {{ tutorial.run_namabip }}
                          </td> -->
                          <td>
                            {{ tutorial.status_bayar == '00' ? 'Lunas' : 'Belum Bayar'  }}
                          </td>
                          <!-- <td>
                            {{ tutorial.run_phone }}
                          </td> -->
                          <td>
                            <button type="button" class="btn btn-rounded btn-info btn-xxs mr-2" (click)="showsPesertas(sendMessageModal, tutorial, 'view')" placement="left" ngbTooltip="Show">
                              <i class="fa fa-eye color-info"></i>
                              </button>
                            <button type="button" class="btn btn-success btn-xxs mr-2" (click)="setKehadiran(tutorial)" placement="left" ngbTooltip="Hadir" *ngIf="tutorial.run_kehadiranText == 'Tidak Hadir'">
                              Hadir
                            </button>
                            <button type="button" class="btn btn-rounded btn-danger btn-xxs mr-2" (click)="showsPembayarans(sendMessagebayarModal, tutorial, 'view')" placement="left" ngbTooltip="Bayar" *ngIf="tutorial.status_bayar != '00'">
                              Bayar
                            </button>
                            <!-- <button type="button" class="btn btn-rounded btn-primary"
                            (click)="actionPembayaran(sendMessageModal, tutorial, 'update')"><span
                              class="btn-icon-left text-primary"><i class="fa fa-pencil color-primary"></i>
                            </span>Edit</button>&nbsp;
      
                            <button (click)="deleteUser(tutorial)" type="button" class="btn btn-rounded btn-danger">
                              <span class="btn-icon-left text-danger"
                                ><i class="fa fa-trash-o color-danger"></i> </span
                              >Remove
                            </button> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div class="d-flex flex-wrap justify-content-between p-2">
                  <ngb-pagination class="pagination-responsive"
                    [collectionSize]="count"
                    [(page)]="page"
                    [pageSize]="pageSize"
                    (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                  >
                  </ngb-pagination>
                  <div class="form-group">
                    <label class="mr-sm-2">Show:</label>
                    <select
                      class="mr-sm-2 custom-select"
                      style="width: auto"
                      name="pageSize"
                      (change)="handlePageSizeChange($event)"
                    >
                      <option *ngFor="let size of pageSizes" [ngValue]="size">
                        {{ size }}
                      </option>
                    </select>
                    entries
                  </div>
                </div>
            </div>
        </div>
    </div>

    </div>
    
   

   
<!--**********************************
    Content body end
***********************************-->
<ng-template #sendMessageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detail Peserta</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="comment-form">
      <!-- Start Detail Pendaftaran -->
      <!-- End Detail Pendaftaran -->
      <div class="row">
        <div class="col-lg-12">
				
          <div class="card">
            <div class="card-header"> Tanggal Pendaftaran <strong>{{fadd_role.createdAt | date : 'd MMM y HH:mm:ss' }}</strong> <span class="float-right">
            <strong>Status Pembayaran:</strong> {{fadd_role.status_bayar == '00' ? 'Lunas' : 'Belum Bayar'}}</span> </div>
            <div class="card-body">
              <div class="row mb-5">
                <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <h6>Informasi Event: </h6>
                  <div>Kategori: {{fadd_role.run_categoryText}}  </div>
                  <div>Ukuran Kaos: {{fadd_role.run_sizekaos}}</div>
                  <div>Nomor BIB: {{fadd_role.run_nobip}} </div>
                  <div>Nama BIB: {{fadd_role.run_namabip}} </div>
                </div>
                <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <h6>Informasi Pribadi:</h6>
                  <div>Nomor KTP/Passport: {{fadd_role.run_idnumber}} </div>
                  <div>Nama Lengkap: {{fadd_role.fullname}} </div>
                  <div>Jenis Kelamin: {{fadd_role.run_gender == 'Male' ? 'Pria' : 'Wanita'}}</div>
                  <div>Tanggal Lahir: {{fadd_role.run_gender | date : 'd MMM y' }}</div>
                  <div>Email: {{fadd_role.run_email}}</div>
                  <div>No.Hp/Telp: {{fadd_role.run_phone}}</div>
                  <div>Kota: {{fadd_role.run_city}}</div>
                  <div>Warga Negara: {{fadd_role.run_nationality}}</div>
                  <div>Perusahaan: {{fadd_role.run_organization}}</div>
                  <div>Pekerjaan: {{fadd_role.run_occupation}}</div>
                </div>
                <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <h6>Kontak Darurat:</h6>
                  <div>Nama Kontak Darurat: {{fadd_role.run_emergencyname}} </div>
                  <div>No. Telp Darurat: {{fadd_role.run_emergencyphone}}</div>
                  <div>Hubungan Kontak Darurat: {{fadd_role.run_emergencyrel}}</div>
                  <div>Golongan Darah: {{fadd_role.run_blood}}</div>
                </div>
              </div>
              <div class="card-header"> Detail Pembayaran <strong>{{fadd_role.paymentText}}</strong> </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">#</th>
                      <!-- <th>Item</th> -->
                      <th>Keterangan</th>
                      <th class="right">Harga</th>
                      <th class="center">Qty</th>
                      <th class="right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="center">1</td>
                      <td class="left strong">Kategori {{fadd_role.run_categoryText}}</td>
                      <!-- <td class="left">Extended License</td> -->
                      <td class="right">{{fadd_role.harga1 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga1 | number}}</td>
                    </tr>
                    <tr>
                      <td class="center">2</td>
                      <td class="left">Biaya Layanan</td>
                      <!-- <td class="left">Instalation and Customization (cost per hour)</td> -->
                      <td class="right">{{fadd_role.harga2 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga2 | number}}</td>
                    </tr>
                    <tr *ngIf="fadd_role.harga3 > 0">
                      <td class="center">3</td>
                      <td class="left">Diskon</td>
                      <!-- <td class="left">Instalation and Customization (cost per hour)</td> -->
                      <td class="right">{{fadd_role.harga3 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga3 | number}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-5"> </div>
                <div class="col-lg-4 col-sm-5 ml-auto">
                  <table class="table table-clear">
                    <tbody>
                      <tr>
                        <td class="left"><strong>Total</strong></td>
                        <td class="right"><strong>{{fadd_role.total | number}}</strong><br>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #sendMessagebayarModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detail Pembayaran</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="comment-form">
      <div class="row">
        <div class="col-lg-12">
				
          <div class="card">
            <div class="card-header"> No Invoice <strong>{{fadd_role.run_order_id }}</strong> <span class="float-right">
            <strong>Status Pembayaran:</strong> {{fadd_role.status_bayar != '00' ? 'Belum Bayar' : 'Bayar'}}</span> </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">#</th>
                      <th>Keterangan</th>
                      <th class="right">Harga</th>
                      <th class="center">Qty</th>
                      <th class="right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="center">1</td>
                      <td class="left strong">Kategori {{fadd_role.run_categoryText}}</td>
                      <td class="right">{{fadd_role.harga1 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga1 | number}}</td>
                    </tr>
                    <tr>
                      <td class="center">2</td>
                      <td class="left">Biaya Layanan</td>
                      <td class="right">{{fadd_role.harga2 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga2 | number}}</td>
                    </tr>
                    <tr *ngIf="fadd_role.harga3 > 0">
                      <td class="center">3</td>
                      <td class="left">Diskon</td>
                      <td class="right">{{fadd_role.harga3 | number}}</td>
                      <td class="center">1</td>
                      <td class="right">{{fadd_role.harga3 | number}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-5"> </div>
                <div class="col-lg-4 col-sm-5 ml-auto">
                  <table class="table table-clear">
                    <tbody>
                      <tr>
                        <td class="left"><strong>Total</strong></td>
                        <td class="right"><strong>{{fadd_role.total | number}}</strong><br>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 float-right">
					<div class="form-group mb-0">
            <button (click)="saveBayar()" class="submit btn btn-danger">Bayar</button>
					</div>
				</div>
      </div>
    </form>
  </div>
</ng-template>